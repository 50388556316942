import React, { useState } from "react";
import backLayer from "../../assets/img/layers/back_layer.png";
import graphic from "../../assets/img/layers/Grafik.png";
import rabbit_huge from "../../assets/img/logo/Logo_rabbit_2xl.png";
import runnin_rabbit from "../../assets/gif/1fiRun(1Sec).gif";
import CircleAnimation from "../CircleAnimation/CircleAnimation";

export default function TokenAllocation() {
  const [percentFill, setPercentFill] = useState(5);

  return (
    <>
      <div className="w-full h-[937px] max-[500px]:h-[660px] relative flex max-[1000px]:flex-col max-[1000px]:justify-start items-center justify-center max-[1000px]:gap-4 max-[1150px]:gap-12 gap-24 overflow-hidden">
        <img
          className="absolute top-0 h-full w-full left-0 object-fill"
          src={backLayer}
          alt="web3"
        />

        {/* Left Side (Desktop) */}
        <div className="w-auto max-[1350px]:h-[400px] h-[500px] z-10 flex flex-col items-start justify-between relative duration-300 max-[1000px]:hidden">
          <p
            onMouseEnter={() => setPercentFill(5)}
            className="cursor-default duration-300 hover:scale-110 hover:translate-x-4 text-[20px] text-white translate-x-[83px]"
          >
            Early Contributors{" "}
            <span className="text-[25px] text-[#F80AFB]">5%</span>{" "}
          </p>
          <p
            onMouseEnter={() => setPercentFill(15)}
            className="cursor-default duration-300 hover:scale-110 hover:translate-x-0 text-[20px] text-white translate-x-[41px]"
          >
            Player Rewards{" "}
            <span className="text-[25px] text-[#F80AFB]">15%</span>{" "}
          </p>
          <p
            onMouseEnter={() => setPercentFill(10)}
            className="cursor-default duration-300 hover:scale-110 hover:-translate-x-5 text-[20px] text-white"
          >
            Project Funds <span className="text-[25px] text-[#F80AFB]">10%</span>{" "}
          </p>
          <p
            onMouseEnter={() => setPercentFill(15)}
            className="cursor-default duration-300 hover:scale-110 hover:translate-x-1 text-[20px] text-white translate-x-[41px]"
          >
            Marketing <span className="text-[25px] text-[#F80AFB]">15%</span>{" "}
          </p>
        </div>

        {/* Center Section (Graphic and Circle Animation) */}
        <div className="max-[500px]:w-[250px] max-[500px]:h-[250px] min-[1000px]:-translate-x-8  max-[1000px]:mt-12 max-[1000px]:w-[400px] max-[1000px]:h-[400px] max-[1350px]:w-[500px] max-[1350px]:h-[500px] w-[600px] h-[600px] flex flex-col items-center pt-32 z-10 max-[1350px]:translate-y-0 translate-y-20 relative duration-300">
          <img
            className="absolute top-0 left-0 z-0 m-auto"
            src={graphic}
            alt="web3"
          />
          <img
            className="absolute max-[500px]:w-14 max-[500px]:h-14 max-[500px]:top-[38px] max-[500px]:-left-4 max-[1000px]:top-[70px] max-[1000px]:-left-5 -top-40 -z-10 max-[1000px]:w-20 max-[1000px]:h-20  m-auto duration-300"
            src={rabbit_huge}
            alt="web3"
          />
          <img
            className="absolute -bottom-8 max-[1350px]:-bottom-0 max-[500px]:bottom-2 object-contain max-[500px]:h-52 max-[500px]:w-44 max-[1350px]:h-80 max-[1350px]:w-52 w-80 h-[512px] duration-300 z-10 "
            src={runnin_rabbit}
            alt="web3"
          />
          <div className="absolute translate-x-2 w-full h-[93%] top-0 left-0 z-0 m-auto z-0">
            <CircleAnimation fillPercentage={percentFill} />
          </div>

          {/* Total Supply (Desktop) */}
          <p className="text-[25px] text-white max-[1000px]:hidden">
            <span className="text-[25px] text-[#F80AFB]">Total Supply</span>{" "}
            <br />
            10 000 000 000 <br />
            <span className="text-[25px] text-[#F80AFB]">$MR Tokens</span>
          </p>
        </div>

        {/* Right Side (Desktop) */}
        <div className="w-auto max-[1350px]:h-[400px] h-[500px] flex flex-col items-end justify-between z-10 duration-300 max-[1000px]:hidden">
          <p
            onMouseEnter={() => setPercentFill(20)}
            className="cursor-default duration-300 hover:scale-110 hover:-translate-x-5 text-[20px] text-white -translate-x-[83px]"
          >
            {" "}
            <span className="text-[25px] text-[#F80AFB]">20%</span> Liquidity{" "}
          </p>
          <p
            onMouseEnter={() => setPercentFill(25)}
            className="cursor-default duration-300 hover:scale-110 hover:-translate-x-4 text-[20px] text-white -translate-x-[41px]"
          >
            {" "}
            <span className="text-[25px] text-[#F80AFB]">25%</span> Presale{" "}
          </p>
          <p
            onMouseEnter={() => setPercentFill(10)}
            className="cursor-default duration-300 hover:scale-110 hover:translate-x-8 text-[20px] text-white"
          >
            {" "}
            <span className="text-[25px] text-[#F80AFB]">10%</span> Team{" "}
          </p>
        </div>

        {/* Total Supply (Mobile) */}
        <div className="z-10 mt-16">
          <p className="text-[25px] max-[600px]:text-[19px] text-white min-[1000px]:hidden">
            <span className="text-[25px] max-[600px]:text-[19px] text-[#F80AFB]">
              Total Supply
            </span>{" "}
            <br />
            10 000 000 000
            <span className="text-[25px] max-[600px]:text-[19px] text-[#F80AFB]">
              {" "}
              $MR Tokens
            </span>
          </p>
        </div>

        {/* For Mobile Version */}
        <div className="min-[1000px]:hidden flex justify-between items-start px-5 z-10 w-full ">
          <div className="flex flex-col items-start leading-[20px] text-start">
            <p
              onClick={() => setPercentFill(5)}
              className="text-[15px] text-white"
            >
              Early Contributors <br />{" "}
              <span className="text-[20px] text-[#F80AFB]">5% </span>{" "}
            </p>
            <p
              onClick={() => setPercentFill(15)}
              className="text-[15px] text-white "
            >
              Player Rewards <br />{" "}
              <span className="text-[20px] text-[#F80AFB]">15% </span>
            </p>
            <p
              onClick={() => setPercentFill(20)}
              className="text-[15px] text-white "
            >
              Liquidity <br />{" "}
              <span className="text-[20px] text-[#F80AFB]">20% </span>{" "}
            </p>
            <p
              onClick={() => setPercentFill(10)}
              className="text-[15px] text-white "
            >
              Project Funds <br />{" "}
              <span className="text-[20px] text-[#F80AFB]">10% </span>{" "}
            </p>
          </div>
          <div className="flex flex-col items-end leading-[20px] text-end">
            <p
              onClick={() => setPercentFill(15)}
              className="text-[15px] text-white"
            >
              <span className="text-[20px] text-[#F80AFB]">15%</span> <br />{" "}
              Marketing{" "}
            </p>
            <p
              onClick={() => setPercentFill(25)}
              className="text-[15px] text-white "
            >
              <span className="text-[20px] text-[#F80AFB]">25%</span> <br />
              Presale{" "}
            </p>
            <p
              onClick={() => setPercentFill(10)}
              className="text-[15px] text-white "
            >
              <span className="text-[20px] text-[#F80AFB]">10%</span> <br />
              Team{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
